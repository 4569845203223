import "../components/cmsShared";
import "./Styles/main.scss";
import { Slick } from "@/pages/vendors/vendor.slick";
import { createAniView } from "@/pages/vendors/vendor.aniView";
import "../components/baiduMap";
import "picturefill";
import "@/pages/base/divUtils";
import { CustomJqueryEvents } from "@/common/customJqueryEvents";
import { qrCodeGenerator } from "../components/cmsShared/qrCode";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { CMS_WAP_URL_ROOT } from "@/consts";
import { KnownCmsPageType } from "@/api";
import {loadContact} from "@/phone";

$(function () {
    createAniView($(".aniview"));
    loadContact();
    const wapHomeUrl = `${CMS_WAP_URL_ROOT}/c${cmsGlobal.Cms.CompanyId}`;
    qrCodeGenerator(wapHomeUrl);

    if (cmsGlobal.Cms.IsCustomDomain && cmsGlobal.KnownPage === KnownCmsPageType.CmsProductDetail) {
        import( "../../pages/modules/imagePhotoswipe");
    }

    const $bannerSlides = $(".banner-slides");

    function initBannerSlides($bannerSlides: JQuery) {
        if ($bannerSlides.find(".banner-slides__item").length) {
            new Slick($bannerSlides, {
                infinite: true,
                speed: 500,
                autoplaySpeed: 3000,
                fade: false,
                autoplay: true,
                prevArrow: '<a href="javascript:void(0);" class="banner-slides__arrow-left"></a>',
                nextArrow: '<a href="javascript:void(0);" class="banner-slides__arrow-right"></a>',
            });
        }
        // 解决图片在slick未加载完成时闪现的问题，结合.banner-slides img的样式使用
        $bannerSlides.find("img").css("visibility", "visible");
    }
    initBannerSlides($bannerSlides);

    $(document.body).on(CustomJqueryEvents.CmsModuleContentUpdatedEvent, function (event, data) {
        if (data.data && data.data.CustomModuleType === "CarouselWithOutIntro") {
            const $bannerSlides = $(".banner-slides");
            initBannerSlides($bannerSlides);
        }
    });

    function setupProductDetailSlides() {
        
        new Slick($(".product-detail__info-slide-imgs"), {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            infinite: false,
            asNavFor: ".product-detail__info-left-imgs-slide",
        });

        const slides = new Slick($(".product-detail__info-left-imgs-slide"), {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            asNavFor: ".product-detail__info-slide-imgs",
            focusOnSelect: true,
            infinite: false,
        });

        $(document).on(
            "click",
            ".product-detail__info-left-arrows .product-detail__info-left-arrows-next",
            function () {
                slides.slickNext();
            },
        );
        $(document).on(
            "click",
            ".product-detail__info-left-arrows .product-detail__info-left-arrows-prev",
            function () {
                slides.slickPrev();
            },
        );
        const length = $(".product-detail__info-left-imgs-slide-item").length;
        if (length > 4) {
            $(".product-detail__info-left-arrows a").removeClass("disabled");
        } else {
            $(".product-detail__info-left-arrows a").addClass("disabled");
        }
    }

    setupProductDetailSlides();
    
});

$(function () {
    //首页产品与服务
    $(document.body).on("click", ".product-category__item", function () {
        const $this = $(this);
        if ($this.is(".product-category__item-active"))
        {
            return;
        } 
        const index = $this.parent(".product-category__list").find(".product-category__item").index($this);
        console.log(index);
        const $productLists = $this.parent(".product-category__list").siblings(".product-category__content").find(".product-category__content-item");
     
        $this.siblings().removeClass("product-category__item-active");
        $this.addClass("product-category__item-active");
        $productLists.removeClass("product-category__content-item-active");
        $productLists.eq(index).addClass("product-category__content-item-active");
    });



$(document).on("mouseenter", ".suspension .a", function(){
    var _this = $(this);
    var s = $(".suspension");
    var isService = _this.hasClass("a-service");
    var isServicePhone = _this.hasClass("a-service-phone");
    var isQrcode = _this.hasClass("a-qrcode");
    if(isService){ s.find(".d-service").show().siblings(".d").hide();}
    if(isServicePhone){ s.find(".d-service-phone").show().siblings(".d").hide();}
    if(isQrcode){ s.find(".d-qrcode").show().siblings(".d").hide();}
});
$(document).on("mouseleave", ".suspension, .suspension .a-top", function(){
    $(".suspension").find(".d").hide();
});
$(document).on("mouseenter", ".suspension .a-top", function(){
    $(".suspension").find(".d").hide(); 
});
$(document).on("click", ".suspension .a-top", function(){
    $("html,body").animate({scrollTop: 0});
});
$(window).scroll(function(){
    var st = $(document).scrollTop();
    var $top = $(".suspension .a-top");
    if(st > 400){
        $top.css({display: 'block'});
    }else{
        if ($top.is(":visible")) {
            $top.hide();
        }
    }
});



});

//导航栏下划线
$(".navigation__item:not('.navigation__item--active, .navigation__item--has-child') > a")
    .addClass("hover-underline")
    .addClass("hover-underline-animation");
